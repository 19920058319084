<template>
  <div class="pay">
    <div class="content">
      <div class="select">
        <img src="../assets/logo.png" alt="" />
        <span>Select your VPN plan</span>
      </div>
      <div class="step1">
        <div>STEP 1</div>
        <span>Enter your email address:</span>
      </div>
      <div class="input">
        <el-input v-model="mail" placeholder="Your email address"></el-input>
      </div>
      <div class="step2">
        <div>STEP 2</div>
        <span>Select a plan that works for you:</span>
      </div>
      <div class="step2-title">During trial operation, limited time offer</div>
      <div class="pay-list">
        <div class="pay-list-one">
          <div class="pay-list-one-title">1 Month</div>
          <div class="pay-list-one-content">
            <div class="pay-list-one-content-one">$</div>
            <span>11.99</span>
          </div>
          <div class="pay-list-one-content-per">per month</div>
        </div>
        <div class="pay-list-two">
          <div class="pay-list-one-title">12 Month <a>+ 3 Month FREE</a></div>
          <div class="pay-list-one-content">
            <div class="pay-list-one-content-one">$</div>
            <span>69.99</span>
          </div>
          <div class="pay-list-one-content-per">per year</div>
        </div>
      </div>
      <div class="total">
        <span>Order total</span>
        <span>$11.99</span>
      </div>
      <div class="btn">
        <el-button>Continue</el-button>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "StripePay",
  data() {
    return {
      mail: "",
      firstActive: true,
    };
  },
  methods: {},
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="less">
.pay {
  width: 100%;
  background: #f7f8f9;
  height: 100vh;
  .content {
    width: 80%;
    margin: 0 auto;
    padding-top: 3rem;
  }
  .select {
    width: 100%;
    display: flex;
    align-items: center;
    margin-bottom: 3rem;
    img {
      width: 4rem;
      height: 4rem;
      margin-right: 1.6rem;
      border-radius: 50%;
    }
    span {
      font-size: 2rem;
      font-family: PingFangSC-Medium, PingFang SC;
      font-weight: 500;
      color: #000000;
    }
  }
  .step1 {
    width: 100%;
    display: flex;
    align-items: center;
    margin-bottom: 2rem;
    div {
      margin-right: 0.9rem;
      width: 5rem;
      height: 2rem;
      background: #5e66f4;
      border-radius: 1.4rem;
      font-size: 1rem;
      font-family: PingFangSC-Medium, PingFang SC;
      font-weight: 500;
      color: #ffffff;
      line-height: 2rem;
    }
    span {
      height: 3.3rem;
      font-size: 2rem;
      font-family: PingFangSC-Medium, PingFang SC;
      font-weight: 500;
      color: #000000;
      line-height: 3.3rem;
    }
  }
  .input {
    margin-bottom: 3rem;
    .el-input {
      font-size: 1.8rem;
    }
    .el-input__inner {
      height: 5rem;
      line-height: 5rem;
    }
  }
  .step2 {
    width: 100%;
    display: flex;
    align-items: center;
    div {
      margin-right: 0.9rem;
      width: 5rem;
      height: 2rem;
      background: #5e66f4;
      border-radius: 1.4rem;
      font-size: 1rem;
      font-family: PingFangSC-Medium, PingFang SC;
      font-weight: 500;
      color: #ffffff;
      line-height: 2rem;
    }
    span {
      // height: 3.3rem;
      font-size: 2rem;
      font-family: PingFangSC-Medium, PingFang SC;
      font-weight: 500;
      color: #000000;
      // line-height: 3.3rem;
    }
  }
  .step2-title {
    padding-left: 5.9rem;
    font-size: 1.2rem;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    color: #000000;
    text-align: left;
  }
  .pay-list {
    display: flex;
    margin-top: 3rem;
    .pay-list-one,
    .pay-list-two {
      width: 22rem;
      height: 20rem;
      background: #ffffff;
      box-shadow: 0px 1px 15px 0px rgba(99, 99, 99, 0.14);
      border-radius: 1rem;
      margin-right: 4.4rem;
      position: relative;
    }
    .pay-list-one-title {
      font-size: 1.2rem;
      font-family: PingFangSC-Medium, PingFang SC;
      font-weight: 500;
      color: #000000;
      position: absolute;
      left: 50%;
      transform: translate(-50%, 0);
      top: 15%;
      white-space: nowrap;
      a {
        color: #5e66f4;
      }
    }
    .pay-list-one-content {
      position: absolute;
      left: 50%;
      top: 50%;
      transform: translate(-50%, -50%);
      font-size: 3rem;
      font-family: PingFangSC-Medium, PingFang SC;
      font-weight: 500;
      color: #000000;
    }
    .pay-list-one-content-one {
      position: absolute;
      top: 0.3rem;
      font-size: 1.4rem;
      left: -1.4rem;
    }
    .pay-list-one-content-per {
      position: absolute;
      left: 50%;
      transform: translate(-50%, 0);
      top: 65%;
    }
  }
  .total {
    display: flex;
    align-items: flex-end;
    margin-top: 2rem;
    span:first-child {
      font-size: 2rem;
      font-family: PingFangSC-Medium, PingFang SC;
      font-weight: 500;
      color: #000000;
      margin-right: 1rem;
    }
    span:last-child {
      font-size: 3rem;
      line-height: 4rem;
      font-family: PingFangSC-Medium, PingFang SC;
      font-weight: 500;
      color: #000000;
    }
  }
  .btn {
    width: 100%;
    justify-items: left;
    margin-top: 2rem;
    .el-button {
      background: #5e66f4;
      padding: 0;
      width: 20rem;
      height: 5rem;
      font-size: 2rem;
      font-family: PingFangSC-Semibold, PingFang SC;
      font-weight: 600;
      color: #ffffff;
      border-radius: 0.8rem;
    }
  }
}
</style>
