<template>
  <div class="hello">
    <div class="content">
      <div class="logo">
        <img src="@/assets/logo.png" alt="" />
      </div>
      <div class="title">Nums</div>
      <!-- <div class="two">BFast VPN</div> -->
      <div class="start">
        <p>Nums</p>
        <p>get start now！</p>
      </div>
      <div class="welcome">Welcome！</div>
      <div class="btn">
        <a @click="goTeamUser">Terms of Service</a>
        <a @click="goPrivacyPolicy">Privacy Policy</a>
        <!-- <a @click="goPay">Get BFast VPN</a> -->
      </div>
    </div>
    <div class="footer">
      <!-- <p>Copyright © 2023 NormalProxy. All rights reserved</p> -->
      <!-- <p>Copyright</p> -->
      <!-- 电话：852-21267435 公司：hongkong zerogap technology co. limited -->
      <div>
        <!-- <a @click="goTeamUser">Terms of Service</a> -->
        <!-- <a @click="goPrivacyPolicy" class="pl10">Privacy Policy</a> -->
        <span class="pl10">
          HELP：<a href="mailto:contact@2ndphone.net">contact@2ndphone.net</a>
        </span>
        <!-- <a class="pl10">Tel：852-21267435</a> -->
        <!-- <a class="pl10">Company: zerogap technology co. limited</a> -->
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "HelloWorld",
  methods: {
    goTeamUser() {
      this.$router.push("termsService");
    },
    goPrivacyPolicy() {
      this.$router.push("privacyPolicy");
    },
    goPay() {
      this.$router.push("pay");
    },
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="less">
.pl10 {
  padding-left: 1rem;
}
.hello {
  width: 100%;
  min-height: 100vh;
  background: #cecbf1;

  box-sizing: border-box;
  .content {
    width: 100%;
    background: #edecff;
    padding-top: 8rem;
    padding-bottom: 2rem;
    box-sizing: border-box;
  }
}
.logo {
  width: 15rem;
  height: 15rem;
  border-radius: 50%;
  overflow: hidden;
  border: 0.3rem solid #ffffff;
  margin: 0 auto;
}
img {
  width: 100%;
  height: 100%;
}
.title {
  font-size: 4rem;
  line-height: 200%;
  margin-top: 1rem;
  font-weight: 600;

  color: #000;
}
.start :first-child {
  margin-left: -1.5rem;
}
.start {
  border-top: 0.1rem solid rgba(67, 35, 239, 0.3);
  border-bottom: 0.1rem solid rgba(67, 35, 239, 0.3);
  padding: 2rem 10rem;
  margin-top: 2rem;
  display: inline-block;
  /* color: rgba(255, 255, 255, 0.7); */
}
p {
  white-space: nowrap;
  font-size: 2rem;
  line-height: 200%;
  padding: 0;
  margin: 0;
  font-family: PingFangSC-Medium, PingFang SC;
  font-weight: 500;
  color: #000;
}
.welcome {
  font-size: 2rem;
  padding: 2rem 0;
  font-family: PingFangSC-Medium, PingFang SC;
  font-weight: 500;
  color: #000;
}
.btn {
  width: 35rem;
  margin: 1rem auto;
  display: flex;
  justify-content: space-between;
}
.btn {
  a {
    width: 13.6rem;
    height: 3.7rem;
    background: rgba(216, 216, 216, 0);
    border-radius: 1.9rem;
    border: 1px solid #4323ef;
    line-height: 3.7rem;
    font-size: 1.6rem;
    font-family: SFPro-Medium, SFPro;
    font-weight: 500;
    color: #4323ef;
    text-decoration: none;
    display: block;
    cursor: pointer;
  }
}
.footer {
  padding: 1rem 0;
  width: 100%;
  text-align: center;
}
.footer span {
  font-size: 1.6rem;
  line-height: 3rem;
  color: #000;
}

.footer {
  div {
    color: #999;
    font-weight: 500;
  }
}
.footer a {
  color: #000;
  cursor: pointer;
  font-size: 1.6rem;
  line-height: 3rem;
}

@media screen and (max-width: 960px) {
  .hello {
    width: 100%;
    min-height: 100vh;
    background: linear-gradient(176deg, #efeeff 0%, #dbd9ff 100%);
    // padding-top: 4rem;
    // padding-bottom: 2rem;
    box-sizing: border-box;
  }
  .footer {
    position: relative;
    div {
      & > a {
        display: block;
      }
      & > span {
        display: block;
      }
    }
  }
}
@media screen and (min-width: 1400px) {
  .content {
    height: calc(100vh - 5rem);
  }
  .footer {
    position: fixed;
    bottom: 0;
    height: 3rem;
  }
}
</style>
