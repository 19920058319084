/*
 * @Author: your name
 * @Date: 2022-11-16 10:50:31
 * @LastEditTime: 2022-11-17 11:50:23
 * @LastEditors: bogon
 * @Description: In User Settings Edit
 * @FilePath: /vpn/src/router/index.js
 */

import Vue from 'vue';
import VueRouter from 'vue-router';
Vue.use(VueRouter);

//路由跳转的组件，要提前注入
import Home from '@/components/HelloWorld.vue';//【地方一】
import PrivacyPolicy from "@/components/PrivacyPolicy.vue"
import TermsService from "@/components/TermsService.vue"
import Pay from "@/components/StripePay.vue"

//路由配置
const router = new VueRouter({
    mode: 'history',
    routes: [{
        path: '/',
        redirect: 'home'
    },
    {
        path: '/home',
        component: Home
    },
    {
        path: '/privacyPolicy',
        component: PrivacyPolicy
    },
    {
        path: '/termsService',
        component: TermsService
    },
    {
        path: '/Pay',
        component: Pay
    }
    ]
});

export default router;