<template>
  <iframe ref="iframe" frameborder="0" src="/user/terms.html"></iframe>
</template>
<script>
export default {};
</script>
<style>
iframe{
    width:100vw;
    height: 100vh;
}
</style>
